import "./FeaturedProject.scss";

import { Col, Row } from "react-styled-flexboxgrid";

import ArrowDown from "../../../img/decoration/arrow-down-left.svg";
import ProjectCard from "../../partials/ProjectCard/ProjectCard.js";
import React from "react";

const FeaturedProject = ({ nodes }) => {
  const filteredPosts =
    nodes && nodes.length
      ? nodes
          .filter(({ frontmatter: featuredPost }) => featuredPost)
          .slice(0, 1)
      : [];
  const featuredPost = filteredPosts.length ? filteredPosts[0] : null;
  return (
    <section className="FeaturedProject">
      <Row>
        <Col xs={12} md={11} mdOffset={1}>
          <h2>Nos projets</h2>
        </Col>
        {featuredPost && (
          <>
            <Col xs={12} md={1} className={"decoration"}>
              <img src={ArrowDown} alt="" />
            </Col>
            <Col xs={12} md={11} className="card-container">
              <div className="back-title">projet</div>
              <div className="back-title inverted">projet</div>
              <ProjectCard
                slug={featuredPost.fields.slug}
                types={featuredPost.frontmatter.types}
                title={featuredPost.frontmatter.title}
                date={featuredPost.frontmatter.date}
                client={featuredPost.frontmatter.client}
                name={featuredPost.frontmatter.name}
                featuredImage={featuredPost.frontmatter.featuredImage}
                isHorizontal={true}
              />
            </Col>
          </>
        )}
      </Row>
    </section>
  );
};

export default FeaturedProject;
