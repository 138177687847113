import "./Partners.scss";

import { Col, Row } from "react-styled-flexboxgrid";

import PreviewCompatibleImage from "../../../components/PreviewCompatibleImage";
import React from "react";
import SectionTitle from "../../partials/SectionTitle/SectionTitle.js";
import { useWindowSize } from "../../../helpers/helpers.js";

const Partners = ({ title, highlighted, PartnerList }) => {
  const { isMobile } = useWindowSize();
  return (
    <section className="Partners">
      <Row className="fullWidth">
        <SectionTitle
          text={title}
          highlight={highlighted}
          isRight={!isMobile && true}
        />
        <Col xs={12}>
          <ul>
            {PartnerList.map((partner, i) => {
              return (
                <li key={i}>
                  <PreviewCompatibleImage
                    className={"image"}
                    imageInfo={{
                      image: !!partner?.image?.publicURL
                        ? partner.image.publicURL
                        : partner.image,
                    }}
                    alt={partner.altText}
                  />
                </li>
              );
            })}
          </ul>
        </Col>
      </Row>
    </section>
  );
};

export default Partners;
