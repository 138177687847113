import { StyledGrid, useMousePosition } from "../helpers/helpers.js";

import Atelier from "../components/sections/Atelier/Atelier.js";
import FeaturedProject from "../components/sections/FeaturedProject/FeaturedProject.js";
import Hero from "../components/sections/Hero/Hero.js";
import LastArticles from "../components/sections/LastArticles/LastArticles.js";
import Layout from "../components/layout/Layout";
import Newsletter from "../components/sections/Newsletter/Newsletter.js";
import Partners from "../components/sections/Partners/Partners.js";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { graphql } from "gatsby";

export const IndexPageTemplate = ({
  hero,
  atelier,
  lastArticles,
  partners,
  newsletter,
  featuredProjects,
}) => {
  const mousePos = useMousePosition();
  return (
    <StyledGrid>
      <ScrollAnimation animateIn="fadeInBottom" duration={1}>
        <Hero {...hero} mousePos={mousePos} />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInBottom" duration={1.5}>
        <Atelier {...atelier} />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInBottom" duration={1.5}>
        <FeaturedProject {...featuredProjects} />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInBottom" duration={1.5}>
        <LastArticles {...lastArticles} />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInBottom" duration={1.5}>
        <Partners {...partners} />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInBottom" duration={1.5}>
        <Newsletter {...newsletter} />
      </ScrollAnimation>
    </StyledGrid>
  );
};

const IndexPage = ({ data }) => {
  const lastArticles = {
    title: data.page.frontmatter.lastArticles.title,
    highlighted: data.page.frontmatter.lastArticles.highlighted,
    posts: data.lastArticles.nodes,
  };

  return (
    <Layout>
      <IndexPageTemplate
        hero={data.page.frontmatter.hero}
        atelier={data.page.frontmatter.atelier}
        partners={data.page.frontmatter.partners}
        lastArticles={lastArticles}
        newsletter={data.page.frontmatter.newsletter}
        featuredProjects={data.featuredProjects}
      />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    featuredProjects: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "project-post" } } }
    ) {
      nodes {
        excerpt(pruneLength: 144)
        id
        fields {
          slug
        }
        frontmatter {
          title
          name
          types
          client
          templateKey
          date(formatString: "MMMM DD, YYYY")
          featuredPost
          featuredImage {
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
    lastArticles: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      nodes {
        excerpt(pruneLength: 144)
        id
        fields {
          slug
        }
        frontmatter {
          title
          tags
          name
          templateKey
          date(formatString: "MMMM DD, YYYY")
          featuredPost
          featuredImage {
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
    page: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        atelier {
          description
          title
          image {
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
        hero {
          subtitle
          title
          image {
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
        lastArticles {
          highlighted
          title
        }
        partners {
          highlighted
          title
          PartnerList {
            altText
            image {
              publicURL
            }
          }
        }
        newsletter {
          subtitle
          title
        }
      }
    }
  }
`;
