import "./LastArticles.scss";

import { Col, Row } from "react-styled-flexboxgrid";

import ArticleCard from "../../partials/ArticleCard/ArticleCard.js";
import GroupButton from "../../partials/GroupButton/GroupButton.js";
import React from "react";
import SectionTitle from "../../partials/SectionTitle/SectionTitle.js";

const LastArticles = ({ title, highlighted, posts }) => {
  const filteredPosts =
    posts && posts.length
      ? posts
          .filter(({ frontmatter: featuredPost }) => featuredPost)
          .slice(0, 2)
      : [];
  return (
    <section className="LastArticles">
      <Row>
        <SectionTitle text={title} highlight={highlighted} />
        {filteredPosts &&
          filteredPosts.map(({ excerpt, fields, frontmatter }, i) => (
            <Col xs={12} md={5} mdOffset={i === 0 ? 1 : 0} key={i}>
              <ArticleCard
                slug={fields.slug}
                tags={frontmatter.tags}
                title={frontmatter.title}
                excerpt={excerpt}
                image={frontmatter.featuredImage}
                withFrame={i === 0}
              />
            </Col>
          ))}
      </Row>
      <Row>
        <Col xs={12} md={4} mdOffset={7} lg={3} lgOffset={8}>
          <GroupButton text={"Voir nos autres articles"} to={"/blog/"} />
        </Col>
      </Row>
    </section>
  );
};

export default LastArticles;
