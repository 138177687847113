import "./Atelier.scss";

import { Col, Row } from "react-styled-flexboxgrid";

import GroupButton from "../../partials/GroupButton/GroupButton.js";
import PreviewCompatibleImage from "../../../components/PreviewCompatibleImage";
import React from "react";

const Atelier = ({ title, description, image }) => {
  return (
    <section className="Atelier">
      <Row>
        <Col xs={12} md={10} mdOffset={1}>
          <div className="Atelier-blocks">
            <div className="Atelier-block with-quarter">
              <PreviewCompatibleImage
                imageInfo={image}
                className="image"
              />
            </div>
            <div className="Atelier-block with-square">
              <h2>{title}</h2>
              <p>{description}</p>
              <GroupButton to={"/about"} text="Découvrir l'atelier" />
            </div>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Atelier;
