import "./Hero.scss";

import { Col, Row } from "react-styled-flexboxgrid";

import PreviewCompatibleImage from "../../../components/PreviewCompatibleImage";
import React from "react";

const Hero = ({ title, subtitle, image, mousePos }) => {
  return (
    <section className="Hero">
      <Row>
        <div className="back">
          <span
            style={{
              transform: `translate(${mousePos.x * -12}px, ${mousePos.y * -12}px)`,
              transition: "transform 2s cubic-bezier(0.06, 0.33, 0.46, 0.87)",
              display: "inline-block",
            }}
          >
            Skals
          </span>
        </div>
        <div
          className="half-image"
          style={{
            transform: `translate(${mousePos.x * -22}px, ${mousePos.y * -22}px)`,
            transition: "transform 2s cubic-bezier(0.06, 0.33, 0.46, 0.87)",
          }}
        >
          <PreviewCompatibleImage imageInfo={image} />
        </div>
        <div className="text">
          <Col xs={12} mdOffset={1} md={7} className="with-arrow">
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
          </Col>
        </div>
      </Row>
    </section>
  );
};

export default Hero;
